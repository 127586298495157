
.country-list {
  .container-grid__item {
    padding-bottom: 13px;
  }
  .container-grid__item > * {
    height: auto;
    margin-top: 10px;
  }
}



.country-list__item {
  color: #002E5E;
  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  span {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .country-list__item {
    span {
      font-size: 12px;
    }
  }
}

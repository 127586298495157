
.kkt {
  padding-top: 18rem;
  color: #fff;

  section {
    height: 40vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__top {
    background: url("/pic/kkt/oae.png") no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
  }

  &__seminar {
    color: #fff;
    display: flex;
    width: 100rem;
    align-items: center;

    &-date {
      height: 44rem;
      text-align: center;
      flex: 1;
      border: 1px solid #fff;
      padding: 7.2rem 2.4rem;

      &-icon {
        // Стили для иконки, если нужны
      }

      &-day {
        font-size: 12rem;
        line-height: 1;
        font-weight: 300;
        margin-top: 3.2rem;
      }

      &-month {
        font-size: 2.4rem;
        font-weight: 200;
      }

      &-time {
        font-size: 2rem;
        font-weight: 200;
      }
    }

    &-text {
      height: 44rem;
      flex: 3;
      padding-left: 6.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h1 {
        font-weight: bold;
      }

      .link-target {
        color: #fff;
      }
    }
  }

  &__first {
    width: 120rem;
    background: #FAFAFA;
    margin: 0 auto;
    height: auto;

    > div {
      width: 100%;
      padding-right: 34.2rem;

      h3 {
        margin-left: 3.2rem;
        font-weight: bold;
        font-size: 2.8rem;
        margin-bottom: 0;
      }
    }

    &.wide {
      width: 120rem;

      > div {
        padding-right: 28rem;
      }
    }
  }

  .container-shadow {
    background: url("/pic/red-arrow-right.svg") no-repeat calc(100% - 2.4rem) top;

    &.long {
      background-image: url("/pic/red-arrow-right-long.svg");
    }
  }

  &__second {
    background: url("/pic/kkt/chinese.png") no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;

    > div {
      width: 90rem;
    }

    &_icon {
      width: 84px;
      margin-right: 2.4rem;
    }

    &_text {
      h2 {
        margin: 0;
        font-size: 3.6rem;
        font-weight: bold;
      }

      font-size: 2.4rem;
      font-weight: 300;
    }
  }

  &__third {
    background: url("/pic/kkt/uz.png") no-repeat;
    background-size: cover;

    > div {
      width: 90rem;
    }

    .icon-list {
      flex: 0 0 140px;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    .icon-text {
      flex: 1;

      h3 {
        font-size: 2.8rem;
        font-weight: bold;
      }

      font-size: 2rem;
      font-weight: 300;
    }

    .icon-item {
      margin-bottom: 6.4rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__tutor-list {
    background: #002E5E;
    padding-left: calc((100% - 120rem)/2);
    display: block!important;
    height: 42vw!important;
    &-header {
      font-size: 2.8rem;
      margin: 4rem 0;
      svg {
        margin-right: 14px;
      }
    }
    &-arrow {
      padding-right: calc((100% - 120rem));
      svg {
        margin-left: 4.8rem;
      }
    }
  }

  &__tutor-item {
    width: 37rem;
    height: 62.1rem;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    color: #333;
    font-size: 1.5rem;

    h4 {
      font-size: 2.2rem;
      font-weight: bold;
    }

    img {
      width: 14rem;
      margin: 0 auto;
    }
  }
  &__form {
    height: auto!important;
    display: block!important;
    padding-left: calc((100% - 120rem)/2);
    background: #fff;
    padding-top: 7rem;
    padding-bottom: 7rem;
    flex-direction: column;
    &-header {
      display: flex;
      align-items: center;
      color: #002E5E;
      margin-bottom: 4rem;
    }
    &-inputs {
      width: 60rem;
    }
  }
}

// Медиа-запрос для экранов с шириной 768px и меньше
@media (max-width: 768px) {
  .kkt {
    padding-top: 0; // 18rem -> 288px
    margin-top: -96px;
    section {
      height: auto;
    }

    &__seminar {
      width: 100%; // Занимаем всю доступную ширину
      flex-direction: column; // Меняем направление flex на вертикальное

      &-date {
        height: 440px; // 44rem -> 704px
        border: none;
        padding: 72px 24px 0; // 7.2rem -> 115px, 2.4rem -> 38px

        &-day {
          font-size: 120px; // 12rem -> 192px
          margin-top: 32px; // 3.2rem -> 51px
        }

        &-month {
          font-size: 24px; // 2.4rem -> 38px
        }

        &-time {
          font-size: 20px; // 2rem -> 32px
        }
      }

      &-text {
        height: auto; // Сбрасываем фиксированную высоту
        padding-left: 0; // Убираем отступ слева
        margin-top: 20px; // Добавляем отступ сверху
        text-align: center;
        padding-bottom: 40px;
      }
    }

    &__first {
      width: 100%; // Занимаем всю ширину

      > div {
        padding-right: 24px; // Убираем отступ справа
        padding-left: 24px;

        h3 {
          margin-left: 10px; // 3.2rem -> 51px (примерно)
          font-size: 18px; // 2.8rem -> 45px
        }
        svg {
          width: 42px;
        }
      }

      &.wide {
        width: 100%;

        > div {
          padding-right: 0;
        }
      }
    }

    &__second {
      > div {
        width: 100%; // Занимаем всю ширину
        padding: 10px;
      }

      &_icon {
        margin-right: 10px; // 2.4rem -> 38px (примерно)
        width: 36px;
        svg {
          width: 28px;
          height:40px;
        }
      }

      &_text {
        h2 {
          font-size: 24px; // 3.6rem -> 58px
        }

        font-size:16px; // 2.4rem -> 38px
      }
    }

    &__third {
      > div {
        width: 100%; // Занимаем всю ширину
      }

      .icon-text {
        h3 {
          font-size: 18px; // 2.8rem -> 45px
        }

        font-size: 12px; // 2rem -> 32px
      }

      .icon-list {
        flex: 0 0 42px;
        align-items: start;
        svg {
          width: 28px;
          height: 36px;
        }
      }

      .icon-item {
        margin-top: 24px!important;
        margin-bottom: 24px!important;
      }
    }

    &__tutor-list {
      padding-left: 20px; // 30rem -> 480px (примерно)
      display: block!important;
      height: auto!important;
      text-align: center;
      &-header {
        margin: 40px 0;
        h2 {
          font-size: 18px!important;
        }
      }
    }

    &__tutor-item {
      width: 100%; // Занимаем всю ширину
      height: auto; // Сбрасываем фиксированную высоту
      font-size: 15px;
      h4 {
        font-size: 22px; // 2.2rem -> 35px
      }

      img {
        width: 150px; // 15rem -> 240px
      }
    }
    .container-shadow {
      background: none;
      &.long {
        background: none;
      }
    }
    &__form {
      padding-left: 24px;
    }
  }
  .link-target {
    color: #002E5E!important;
  }
}
